import React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import { SliceZone } from '@prismicio/react'
import { Layout } from '../../components/Layout'
import { components } from '../../slices'

function Event(props) {
  console.log('Product props: ', props)
  const { prismicEvent } = props.data
  const event = prismicEvent.data
  const eventInfoSlice = event.body.find(item => item.slice_type === 'event_info');
  const seo = {
    title: prismicEvent.data.seo_title || prismicEvent.data.title.text,
    description: prismicEvent.data.seo_description || eventInfoSlice.primary.description.text.substring(0, 297) + "...",
    keywords: prismicEvent.data.seo_keywords,
  }
  console.log('seo: ', seo)


  const {title, start_time, finish_time, body} = event
  const cntx = {title, start_time, finish_time, restLink:event.restaraunt_link}
  console.log('cntx: ', cntx)
  console.log('Link to rest: ', event.restaraunt_link)

  const topMenu = props.data.prismicTopMenu || {}
  const footer = props.data.prismicFooter || {}

  return (
    <Layout topMenu={topMenu.data} footer={footer} seo={seo}>
      <SliceZone slices={body} components={components} context={cntx}/>
    </Layout>
  )
}

export default Event

export const query = graphql`
  query($id: String!) {
    prismicEvent(id: { eq: $id }) {
      data {
        seo_title
        seo_description
        seo_keywords
        title {
          text
        }
        start_time
        finish_time
        body {
          ... on PrismicEventDataBodyJsonForm {
            primary {
              json_config
            }
          }
          ... on PrismicSliceType {
            slice_type
          }
          ... EventDataBodyEventInfo
        }
        restaraunt_link {
          id
          uid
          link_type
          type
          url
          document {
            ... on PrismicRestaurantDetail {
              id
              data {
                body {
                  ... on PrismicRestaurantDetailDataBodyRestaurantDetail {
                    id
                    slice_type
                    primary {
                      address
                      rest_name
                      logo {
                        url
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    prismicTopMenu {
      ...TopMenuFragment
    }
    prismicFooter {
      ...FooterFragment
    }
  }
`